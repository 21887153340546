.audio-player-day-container {
  display: flex;
  gap: 1rem;

  .audio-player-day-left {
    background: #FCFAF3;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
    padding: 1rem 3rem;

    .audio-list {
      display: table;
      border-collapse: separate;
      border-spacing: 2rem;

      .audio-list-item {
        display: table-row;

        .headphone-container,
        .audio-text-container,
        .wave-container {
          display: table-cell;
          text-align: left;
          vertical-align: middle;
        }

        .audio-text-container {
          cursor: pointer;
          white-space: nowrap;
        }

        .audio-text-container {
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: $color-blue-4;
        }
      }

      .audio-list-item.active {
        .audio-text-container {
          color: $color-yellow;
          font-weight: 500;
        }
      }

      .audio-list-item.non-active {

        .headphone-container,
        .wave-container {
          opacity: 0;
        }
      }

    }
  }

  .audio-player-day-right {
    background: #F4F5D8;
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 4rem;
    padding: 1rem 7rem;

    .audio-player-current {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      width: 20rem;

      input[type="range"] {
        width: 20rem;
      }

      .times {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .current-time {
          margin: 0;
        }
      }
    }

    .audio-player-day-control {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 3rem;
    }
  }
}