.message-box-container {
  z-index: 20000;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 5rem;
  background-color: $color-light-1;
  border: 1px solid black;
  width: 45rem;
}

.message-icon-danger {
  font-size: 4rem;
  color: #e84c3c;
}

.message-icon-info {
  font-size: 4rem;
  color: $color-yellow;
}

.alert {
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: #494e53;
  font-family: $font-monserrat;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
  text-align: center;
  letter-spacing: 0.4px;
}

.message-box-buttons button, .message-box-buttons a {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  background: $color-yellow;
  border: 0.539455px solid $color-light2;
  box-sizing: border-box;
  border-radius: 291.845px;
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 3.3rem;
  text-align: justify;
  color: $color-light-1;
  text-align: center;
  width: 15rem;
}