.conclusions-container {
  font-family: $font-monserrat;
  padding-top: 10rem;
  text-align: center;
  margin: 0 auto;

  .conclusions-header {
    width: 70rem;
    text-align: center;
    margin: 0 auto;

    .title-container {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 17rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 5rem;
        line-height: 6.2rem;
        text-align: justify;
        color: #C7AF37;
        text-align: center;
      }
    }

    .subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: justify;
      color: #8E9168;
      text-align: center;
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .player-title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.0rem;
      color: #8E9168;
    }

    .week-review {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: #43515C;
      margin-top: 5rem;
      text-align: left;
    }
  }

  .conclusions-questions-container {
    margin-top: 2rem;
    background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 58.1%, rgba(245, 244, 241, 0.19) 97.37%), #F4F5D8;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .question-list {
      width: 60rem;
      margin: 0 auto;

      .question-list-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
        margin-bottom: 5rem;

        .counter {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          line-height: 157.9%;
          text-align: justify;
          color: #C7AF37;
        }

        .quiestion-item-container {

          .question-item {
            text-align: left;
            style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 157.9%;
            color: #43515C;
          }

          .list-decorator {
            text-align: left;

            img {
              width: 50rem;
            }
          }

        }
      }
    }


  }

  .conclusions-footer-container {
    width: 75rem;
    margin: 0 auto;
    padding-top: 5rem;

    .conclusions-footer {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: #43515C;
      text-align: left;
    }
  }

  .conclusions-whatsnext-link-container {
    margin-top: 3rem;
    margin-bottom: 3rem;

    .whatsnext-link-text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 149.4%;
      text-align: center;
      letter-spacing: 0.08em;
      color: #F5EFDA;

      background-color: #516573;
      border-radius: 2.6rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      .link-img {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}