.activate-registration-container {
  background: linear-gradient(180deg, rgba(245, 239, 218, 0.97) 34.76%, rgba(245, 239, 218, 0) 100%), $color-white;
  min-height: calc(100vh - 300px);
  font-family: $font-monserrat;
  text-align: center;
  position: relative;

  .logo-container {
    margin-top: 3rem;
    text-align: right;

    img {
      margin-right: 5rem;
    }
  }
}

.subscription-text {
  display:none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $color-yellow-3;
  margin-top: 3rem;
}

.subscription-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28.43px;
  line-height: 35px;
  color: $color-blue-2;
}

.circle-container {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.activate-registration-container.unscubscribe {
  .logo-container {
    margin-top: 3rem;
    text-align: center;

    img {
      margin-right: 0;
    }
  }
}