.landing-container {
  background-color: #F5EFDA;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 100rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  
  .landing-inner {
    display: flex;
    flex-direction: column;
  }
  .landing-inner-right {
    flex: 1 0;
    background-repeat: no-repeat;
    background-position: 60% 30%;
    
  }
  .landing-inner-left {
    flex: 2 0;
  }

  .landing-inner-top {
    padding: 4rem;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .landing-inner-bottom {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .landing-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 6rem;
    line-height: 82px;
    color: $color-blue-2;
  }
  
  .landing-title-yellow {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 6rem;
    line-height: 82px;
    color: $color-yellow-2;
  }

  .landing-text-container-bottom {
    text-align:center;
  }

  .landing-text-bold {
    font-family: 'Montserrat';
    color: $color-blue-2;
    font-weight: 600;
    font-size: 2rem;
  }

  .landing-text-yellow {
    font-family: 'Montserrat';
    color: $color-yellow;
    font-size: 1.8rem;
  }
  
  .landing-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 146.9%;
    color: $color-blue-2;
    margin-top: 2rem;
  }

  .landing-container-modal-close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    cursor: pointer;
    z-index: 5;
    font-size: 2rem;
  }

  .landing-button-accept {
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    line-height: 33px;
    text-align: justify;
    color: $color-light-1;
    background: #C7AF37;
    border-radius: 3rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 7.6rem;
    border: none;
    margin: 0 auto;
    margin-top: 3rem;
  }
}