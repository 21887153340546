.fa-bars {
  color: $color-white;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.mobil-lang {
  display: none;
}

//@media only screen and (max-device-width: 991.98px) {
@media only screen and (max-width: 991.98px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-container {
    width: 100vw !important;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 7rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    grid-gap: normal;
    padding-top: 4rem;
  }

  .nav-menu.active {
    background: #f6f0db;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar {
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      font-size: 2.5rem;
    }

    .dropdown-link {
      font-size: 2rem;
      line-height: normal;
      padding-top: 0rem;
    }

    .nav-links:hover {
      border-radius: 0;
    }

    .mobil-lang {
      display: none;
    }
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 3rem;
    cursor: pointer;
  }

  .fa-times {
    color: $color-white;
    //font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    color: $color-white;
    //font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: $color-white;
    transition: 250ms;
  }

  .fa-caret-down {
    display: none;
  }

  .dropdown-menu {
    width: auto;
    position: relative;
    top: 1rem;

    li {
      text-align: center;
    }
  }

  .nav-item {
    min-height: 5rem;
    height: auto;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .mobil-lang {
    display: flex;
  }

  .mobil-lang-container {
    gap: 4rem;
    margin-top: 2rem;
  }

  .lang-switch-container {
    display: none;
  }

  .dropdown-menu li {
    padding-left: 0rem;
  }
}