.asking-audio-container {
  text-align: center;
  font-family: $font-monserrat;
  padding-left: 27rem;
  padding-right: 5rem;
  margin-top: -5rem;
  padding-bottom: 5rem;

  .asking-audio-footer {
    margin-top: 8rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-2;
    margin-left: -10rem;
    margin-right: 10rem;
  }

  .asking-audio-title {
    font-style: normal;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.6rem;
    color: $color-blue-3;
  }

  .asking-audio-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.5rem;
    color: $color-yellow-2;
  }

  .asking-audio-text1 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 2.6rem;
    color: $color-yellow-3;
    margin-top: 3rem;
  }

  .asking-audio-text2 {
    font-style: italic;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.6rem;
    color: $color-green-1;
    margin-top: 1rem;
  }

  .asking-audio-list {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.0rem;
    color: $color-blue-2;
    margin-top: 3rem;
    height: 20rem;
    width: 35rem;
    margin-left: 13rem;

    li {
      margin-bottom: 1rem;
    }
  }

  .left-side {
    text-align: left;
  }

  .right-side {
    img {
      width: 100%;
    }
  }

  .asking-audio-center {
    display: flex;
    margin-top: 10rem;
  }

  .audio-player-homepage {
    margin-top: 3rem;
    display: inline-block;
    margin-left: -20rem;
  }

  .below-audioplayer {
    text-align: center;
    margin-top: 2rem;

    .below-audioplayer-1 {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: $color-yellow;
    }

    .below-audioplayer-separator {
      margin-top: 5rem;
    }

    .below-audioplayer-2 {
      margin-top: 2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $color-yellow-3;
    }
  }
}