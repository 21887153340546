.contact-container {
  padding-top: 5rem;
  padding-bottom: 10rem;

  .contact-title {
    font-style: normal;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 120%;
    color: $color-blue-1;
  }

  .contact-inner {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 10rem;

    .contact-left {
      margin-left: 15rem;
      margin-top: 15rem;

      .img-container {
        width: 34.9rem;
        height: 54.2rem;
        border-radius: 3rem;
      }
    }

    .contact-right {
      width: 60rem;
      margin-top: 3rem;
      position: relative;
    }
  }

  .contact-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5rem;

    .input-container {
      width: 25rem;

      .input-label {
        font-style: normal;
        font-weight: 400;
        font-size: 2rem;
        line-height: 120%;
        color: $color-blue-1;
      }

      input.input,
      textarea.input {
        border: none;
        border-bottom: 1px solid $color-blue-1;
        outline: none;
        height: 4rem;
        font-family: $font-monserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        color: $color-blue-1;
        resize: none;
      }

      textarea.input {
        margin-top: 1rem;
        height: 10rem;
      }

      .form-buttons-container {
        button.button {
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 120%;
          color: $color-white;
          background: $color-yellow;
          border-radius: 3rem;
          width: 24rem;
          height: 5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 1.5rem 7.6rem;
          border: none;
        }
      }
    }

    .input-container.small {
      width: 20rem;

      .input-label {
        font-size: 1.3rem;
      }

      input.input {
        font-size: 1.2rem;
      }

    }

  }
}

.follow-container {
  position: absolute;
  right: 1rem;
  top: 33rem;

  .follow-inner {
    .follow-title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 120%;
      color: $color-blue-1;
    }

    .follow-text {
      width: 15rem;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 120%;
      color: $color-blue-1;
      margin-top: 1rem;
    }

    .follow-icons {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.contact-footer-container {
  margin-top: 5rem;
  text-align: center;

  .contact-footer-inner {
    width: 58rem;
    margin-left: auto;
    margin-right: auto;

    .footer-title {
      font-style: normal;
      font-weight: 500;
      font-size: 21.33px;
      line-height: 26px;
      color: $color-blue-4;
      text-align: left;
    }

    .footer-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $color-yellow-3;
      text-align: left;
      margin-top: 2rem;
    }
  }
}