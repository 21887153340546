.origins-container {
  background: linear-gradient(180deg, rgba(244, 245, 216, 0) 13.77%, #F4F5D8 91.61%);
  font-family: $font-monserrat;
  padding-top: 5rem;
  padding-left: 25rem;
  padding-right: 25rem;
  padding-bottom: 5rem;

  .origins-title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 3.5rem;
    color: $color-blue-1;
    text-align: center;
  }

  .origins-text1 {
    margin-top: 5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: $color-blue-1;
  }

  .map-container {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 4rem;


    .map-inner {
      .map {
        margin-top: 2rem;
        margin-right: auto;
        margin-left: auto;
        min-width: 50rem;
        min-height: 40rem;
        width: 50rem;
        height: 40rem;
      }
    }

    .map-title {
      margin-top: 2rem;
      font-style: normal;
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 2.6rem;
      text-align: justify;
      text-align: center;

      /* gold */
      color: $color-yellow;
    }

    .map-text {
      width: 70rem;
      margin: 0 auto;
      padding-top: 2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $color-yellow-3;
    }
  }

  .origins-text3 {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-4;


  }
}

.team-container {
  background: linear-gradient(180deg, rgba(245, 239, 218, 0) 0%, $color-light-1 64.88%);
  font-family: $font-monserrat;
  padding-top: 5rem;
  padding-left: 25rem;
  padding-right: 25rem;
  padding-bottom: 5rem;

  .team-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: $color-blue-1;
  }

  .team-text {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $color-blue-1;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;

    .team-map {
      width: 50rem;
      height: 40rem;
      margin: 0 auto;
    }
  }
}

.members-container {
  font-family: $font-monserrat;
  padding-top: 5rem;
  padding-left: 35rem;
  padding-right: 25rem;
  padding-bottom: 5rem;

  .members-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 100%;
    text-align: justify;
    color: $color-blue-1;
  }

  .members-members {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5rem;

    .member-image {
      text-align: center;
    }

    .member-text {
      width: 20rem;
      font-style: normal;
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 100%;
      text-align: left;
      color: $color-blue-1;
    }

    img {
      height: 10rem;
    }
  }
}