html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100vh;
  //font-size: 1.6rem;
  font-family: $font-monserrat;
}

#root {
  height: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.header {
  max-height: 1080px;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* layout */
.clear-all {
  clear: both;
}

.hidden {
  display: none;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-decorator {
  width: 4rem;
  height: .5rem;
  margin-top: 1rem;
}

.fade-in-visible {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-out-visible {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-in-zero {
  opacity: 1;
  max-height: 5000px;
  transition: opacity 1s, max-height 1s ease;
}

.fade-out-zero {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 1s, max-height 1s ease;
}

.main-content {
  min-height: 100vh;
}

button {
  cursor: pointer;
}

.error {
  font-family: $font-monserrat;
  color: red;
}

.grecaptcha-badge {
  opacity: 0;
}

.scrollto {
  position: absolute;
  top: -10rem;
}

.scrollto8 {
  position: absolute;
  top: -7rem;
}

.input-container {
  position: relative;
}

/* PROGRAM MODAL */
.open-program {
  position: absolute;
  cursor: pointer;
  z-index: 10000;
  top: 2rem;
  left: 2rem;

  img {
    width: 3rem;
  }

  .fade-in-image-close,
  .fade-in-image-open {
    display: flex;

    .gotodays {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 93.9%;
      color: $color-yellow;
      width: 1rem;
    }
  }

}

.program-container-modal {
  background-color: $color-white;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 4rem;
  width: 100rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  border-radius: 10px;

  .program-container-modal-close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    cursor: pointer;
  }
}

.tmphidden {
  display: none !important;
}

.activate-registration-container {
  padding: 5rem;
}

.nopage-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-family: $font-monserrat;

  .nopage-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: $color-blue-1;
  }

  .nopage-content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: $color-blue-1;
  }

  .nopage-button {
    margin-top: 5rem;
  }
}

.whatsnext-link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .whatsnext-link-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-4;
  }
}

.activate-registration-container {
  font-size: 2rem;
}

.link {
  cursor: pointer;
}

.login-info-container {
  margin-top: 2rem;
}

.link {
  cursor: pointer;
}

/* WORDPRESS */
.yellow-text {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 21.43px !important;
  line-height: 145% !important;
  color: $color-yellow !important;
}

.button-general {
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 33px;
  text-align: justify;
  color: #F5EFDA;
  background: #C7AF37;
  border-radius: 3rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 4rem;
  border: none;
  margin-top: 0px;
  margin-top: 3rem;
}

.tag {
  background: #F5EFDA;
  border-radius: 3px;
  display: inline-block;
  padding: 1rem 1.5rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: $color-blue-2;
}

.link-general {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  color: $color-yellow;
}

.banner-general {
  max-width: 100%;
}
.aligncenter {
  text-align: center;
}
