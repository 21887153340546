.howitworks-container {
  background: linear-gradient(180deg, rgba(245, 239, 218, 0) 0%, $color-light-1 64.88%);
  padding: 5rem 0rem 0rem 5rem;
  text-align: center;
  font-family: $font-monserrat;

  .howitworks-title {
    .title1 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: $color-yellow;
    }

    .title2 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: $color-blue-1;
    }
  }

  .howitworks-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-align: center;
    color: $color-green-1;
  }

  .howitworks-part1-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
    margin-top: 5rem;

    .part1-img img {
      width: 27rem;
      margin-top: 5rem;
    }

    .part1-text {
      width: 45.5rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
      color: $color-blue-1;
      text-align: left;
    }

    .readmore {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #D3BD2A;
      cursor: pointer;
    }
  }

  .howitworks-part1-list-container {
    width: 74rem;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;

    .howitworks-list {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding-bottom: 2rem;
    }

    .list-text {
      font-style: normal;
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 2.1rem;
      color: $color-blue-1;
      text-align: left;
    }
  }

  .arrow-down-container {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }
}

.howitworks-2-container {
  background: linear-gradient(180deg, #DFE6D6 0%, #F4F5D8 10%, rgba(244, 245, 216, 0) 15%);

  .howitworks-2-testimony-container {
    padding-top: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    align-items: center;

    .howitworks-2-testimony-text {
      width: 25rem;
      font-style: italic;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: justify;
      color: $color-blue-1;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2rem 2rem;
      display: none;
    }

    .howitworks-2-testimony-image img {
      width: 28rem;
    }
  }

  .howitworks-2-header {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 5rem;

    .howitworks-2-header1 {
      font-style: normal;
      font-weight: 600;
      font-size: 5rem;
      line-height: 6.2rem;
      color: $color-yellow;
    }

    .howitworks-2-header2 {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      text-align: center;
      color: $color-yellow-3;
    }

    .howitworks-2-header3 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.6rem;
      line-height: 4.4rem;
      text-align: center;
      color: $color-green-1;
      margin-top: 2rem;
    }
  }

  .howitworks-2-join-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 13rem;
    margin-top: 5rem;
    padding-bottom: 5rem;
  }

  .individual-button,
  .group-button {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.4rem;
    text-align: center;
    color: $color-light-1;
    box-sizing: border-box;
    border-radius: 54.1rem;
    width: 38rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .individual-button {
    background: $color-green-1;
    border: 1px solid $color-yellow;
  }

  .group-button {
    background: $color-yellow;
    border: 1px solid $color-blue-2;
  }

  .individual-icon,
  .group-icon {
    text-align: center;
    margin-top: 10rem;
    min-height: 5rem;
  }

  .individual-list,
  .group-list {
    padding-left: 5rem;

    ul {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-4;
      margin-top: 2.5rem;

      li {
        padding-bottom: 2rem;
      }
    }
  }
}