.header {
  height: calc(100vh - 200px);
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.header-container {
  text-align: center;
  font-family: $font-monserrat;

  .header-title {
    font-size: 3.8rem;
    color: $color-yellow;
    font-weight: 600;
    padding-top: 10rem;
  }

  .header-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
    color: $color-green-1;
  }

  .header-text {
    margin-top: 9rem;
    font-style: normal;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 4.6rem;
    text-align: center;
    color: $color-white;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    width: 80rem;
  }
}

.header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2rem;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}

.header-button {
  background: rgba(245, 239, 218, 0.75);
  border: 3px solid $color-yellow-2;
  border-radius: 2.6rem;
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 2.6rem;
  color: $color-blue-4;
  padding: 0.3rem 2rem 0.3rem 2rem;
}