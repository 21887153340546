.wn-header-container {
  text-align: center;
  font-family: $font-monserrat;
  margin-top: 10rem;
  margin-bottom: 2rem;

  .youdidit {
    font-style: normal;
    font-weight: 700;
    font-size: 6.2rem;
    line-height: 93%;
    text-align: center;
    color: $color-light-1;
    text-transform: uppercase;
    width: 23rem;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0px -2px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4.6rem;
    text-align: justify;
    color: $color-yellow;
    text-transform: uppercase;
    text-align: center;
    margin-top: 2rem;
  }

  .intro {
    font-style: normal;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.6rem;
    color: $color-yellow-3;
    width: 88rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
    text-align: left;
  }

  .wn-h-footer-container {
    width: 66rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;

    .wn-h-footer {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: left;
      color: $color-blue-4;
    }
  }
}

.participate-container {
  font-family: $font-monserrat;
  background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 58.1%, rgba(245, 244, 241, 0.19) 97.37%), #F4F5D8;
  padding-top: 3rem;
  padding-bottom: 6rem;

  .participate-inner {
    width: 72rem;
    margin-right: auto;
    margin-left: auto;
  }

  .participate-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 90rem;
    margin-left: -5rem;

    .participate-title {
      font-style: normal;
      font-weight: 500;
      font-size: 2.85rem;
      line-height: 3.5rem;
      text-align: justify;
      color: $color-blue-2;

      .online {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: justify;

        /* Blue Contrast */
        color: $color-blue-2;
      }
    }

  }

  .community-intro-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5rem;
    width: 72rem;

    .community-intro {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: justify;
      color: $color-green-1;
      width: 48rem;
    }
  }


  .community-contact {
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 2.6rem;
    text-align: justify;
    color: $color-yellow;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .community-contact-intro {
    width: 60rem;
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-4;
  }

  .community-list-container {
    margin-top: 6rem;

    .list-opener {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;
      cursor: pointer;

      .list-title {
        font-style: normal;
        font-weight: 500;
        font-size: 2.13rem;
        line-height: 2.6rem;
        text-align: justify;
        color: $color-blue-4;
      }

      .list-items {
        overflow-y: hidden;
      }

      .community-list-item {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: left;
        color: $color-yellow-3;
        cursor: auto;
        margin-top: 2rem;
      }

      .separator {
        margin-top: 1rem;
      }
    }
  }

  .seminar-container {
    margin-top: 6rem;

    .seminar-list {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-4;
      margin-left: 2rem;
    }

    .seminar-footer {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-4;
      margin-top: 3rem;
    }
  }


}

.youcan-container {
  text-align: center;


  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1.5rem;
    color: $color-blue-4;
    margin-top: 5rem;
    display: inline-block;
  }

  .title::after {
    width: 100%;
    height: 0.4rem;
    background-color: $color-yellow;
    content: ' ';
    display: inline-block;
  }

  .list-container {
    margin-top: 5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;

    .break {
      flex-basis: 100%;
      height: 0;
    }

    .item {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .item-1 {
      flex-basis: 22rem;
    }

    .item-2 {
      flex-basis: 23rem;
    }

    .item-3 {
      flex-basis: 23rem;
    }

    .item-4 {
      flex-basis: 23rem;
    }

    .item-5 {
      flex-basis: 23rem;
    }

    .item-title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: $color-yellow-3;
      text-align: left;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .item-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: #566872;
      text-align: left;
    }

    .item-separator {
      align-self: flex-start;
      display: flex;
      flex-direction: column;

      .spacer {
        height: 10rem;
      }

      img {
        height: 19rem;
        width: 0.1rem;
      }
    }

    .item-separator-bottom {
      img {
        height: 10rem;
        width: 0.1rem;
      }
    }

  }
}

.wn-footer-container {
  margin-top: 5rem;
  background-color: #F9F5E9;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .quote-container {
    width: 45rem;
    margin-right: auto;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .quote {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    color: $color-yellow-3;
    padding-top: 3rem;
  }

  .footer-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15rem;
    align-items: center;

  }
}

.navigation-container {
  text-align: center;
  margin-top: 5rem;

  .back-text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-4;
  }
}