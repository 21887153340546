@media only screen and (max-width: 991.98px) {
  #root {
    max-width: 100%;
    min-width: 100%;
  }

  .program-container-modal-close {
    font-size: 3rem;
  }

  .signup-container {
    width: 90%;
    overflow-y: scroll;
    height: calc(100vh - 50px);

    .signup-container-close {
      padding: 1.5rem;
    }
  }

  .audio-player {
    .audio-player-day-container {
      flex-direction: column;
      margin-top: 2rem;

      .audio-player-day-left {
        border-top-left-radius: 0rem;
        border-bottom-left-radius: 0rem;

        .audio-list {
          .audio-list-item {
            .audio-text-container {
              white-space: normal;
            }
          }
        }
      }

      .audio-player-day-right {
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
      }
    }

    input[type="range"] {
      width: 100%;
    }

    .play-pause {
      img {
        width: 5rem;
        height: auto;
      }
    }

    .audio-player-day-right {
      .play-pause {
        img {
          width: auto;
          height: auto;
        }
      }
    }
  }

  /* navbar */
  .navbar {
    padding-bottom: 0;
    padding-top: 0;
    justify-content: space-between;

    .navbar-brand-name {
      margin-left: -3rem;
    }

    a {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* show program */
  .program-container-modal {
    width: 90%;
    height: 60rem;
    overflow-y: scroll;
    z-index: 10000;

    .ind-program-container {
      .row-2 {
        margin-top: 4rem;
      }
    }

  }

  /******************* HOMEPAGE **************/
  /* header */
  .header {
    max-height: calc(100vh - 77px);
  }

  .header-container {
    .header-title {
      padding-top: 5rem;
    }

    .header-subtitle {
      margin-top: 2rem;
    }

    .header-text {
      margin-left: 0;
      width: 100%;
      text-align: center;
      font-size: 2rem;
      line-height: 3rem;
      margin-top: 4rem;
    }
  }

  .header-buttons {
    gap: 2rem;
    right: auto;
    left: auto;
    width: 100%;
    justify-content: space-around;
    bottom: 3rem;
  }

  /* video */
  .asking-video-container {
    padding: 5rem 2rem 10rem 2rem;

    .asking-video-title {
      text-align: left;
    }

    .asking-video-subtitle {
      text-align: left;
    }

    .asking-video-header-container {
      flex-direction: column;
      gap: 5rem;
      width: 100%;
    }

    .asking-video-footer-container {
      width: 100%;
    }

    .circle {
      opacity: 0.5;
    }

    .asking-video-video {
      position: relative;
      padding-top: 56.25%
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  /* benefits */
  .benefits-container {
    padding: 2rem 2rem 2rem 2rem;

    .benefits-inner {
      flex-direction: column;
      width: 100%;

      .benefits-text {
        font-size: 4rem;
      }
    }

    .benefits-items-separator {
      display: none;
    }

    .benefits-list {
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: 12px 0px;
      background-size: contain;
      background: none !important;
      font-size: 1.4rem;
    }
  }

  /* audio */

  /* audio vertical */

  .asking-audio-vertical {
    .audio-player {
      width: 100%;
    }

    .ap-audio-player {
      width: 100%;
      flex-direction: column;

      .audio-player-left {
        width: 100%;

        .ap-player-source-selector {
          display: flex;
          justify-content: space-around;
          margin-bottom: 3rem;
        }


      }

      .audio-player-right {
        width: 100%;

        .current-time {
          margin-left: 0;
        }

        .audio-player-current {
          width: 100%;
        }

        .progress-bar-container {
          width: 100%;
        }
      }

      .source-duration {
        font-size: 1.5rem;
        text-decoration: underline;
      }
    }
  }


  .asking-audio-container {
    padding: 2rem 2rem 2rem 2rem;

    .audio-player-homepage {
      margin-top: 1rem;
    }

    .asking-audio-footer {
      margin-left: 0;
      margin-right: 0;
    }

    .asking-audio-subtitle {
      margin-top: 1rem;
    }

    .asking-audio-list {
      margin-left: 2rem;
      width: 99%;
      height: auto;
      line-height: 4rem;
    }

    .audio-player {
      margin-left: 0rem;

      .source {
        padding: 1rem;
        margin-bottom: 0;
      }
    }

    .asking-audio-center {
      flex-direction: column-reverse;
    }

    .right-side {
      img {
        width: 100%;
      }
    }
  }

  /* testimony */

  .testimony-container {
    padding: 5rem 2rem 5rem 2rem;

    .testimony {
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      flex-direction: column;
    }

    .testimony-text {
      width: 100%;
    }

    .name {
      margin-top: 2rem;
    }

    .testimony-image {
      img {
        width: 100%;
      }
    }

    .slick-prev {
      left: -1rem;
      display: none;
      top: 18rem;
    }

    .slick-next {
      right: 1rem;
      display: none;
      top: 18rem;
    }

    .slick-slide {
      width: 90vw;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }

    .testimony-share {
      margin-top: 2rem;

    }
  }

  /* how it works */

  .howitworks-container {
    .arrow-down-container {
      padding-top: 3rem;
    }

    padding-left: 1rem;
    padding-right: 1rem;

    .howitworks-subtitle {
      margin-top: 2rem;
    }

    .howitworks-part1-container {
      flex-direction: column;
      gap: 2rem;
      margin-top: 1rem;

      .part1-text {
        width: auto;
      }
    }

    .logo {
      margin-bottom: 2rem;

      img {
        width: 100%;
      }
    }

    .explanation {
      flex-direction: column;
      gap: 5rem;
    }

    .howitworks-part1-list-container {
      width: auto;

      .list-text {
        line-height: 2.8rem;
      }
    }
  }

  /* how it works 2 */
  .howitworks-2-container {

    .howitworks-2-testimony-container {
      flex-direction: column;
      padding-top: 5rem;

      .howitworks-2-testimony-text {
        line-height: 2.6rem;
        width: 90%;
        display: none;
      }
    }

    .howitworks-2-header1,
    .howitworks-2-header2 {
      display: inline-block;
    }


    .howitworks-2-join-container {
      flex-direction: column;
      gap: 5rem;
      margin-left: 2rem;
      margin-right: 2rem;

      .individual-button,
      .group-button {
        width: auto;
      }

      .individual-icon,
      .group-icon {
        margin-top: 5rem;
      }
    }
  }

  .gift-container {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 5rem;
    padding-bottom: 2rem;

    .explanation {
      .gift-video-video {
        position: relative;
        padding-top: 56.25%
          /* Player ratio: 100 / (1280 / 720) */
        ;
        margin: 1rem auto;
      }

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }

      .text2 {
        margin: 3rem auto;
      }
    }

    .readmore {
      width: 100%;
      display: block;
      padding: 0;
      margin: 0;
    }
  }

  .origins-container {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

    .map {
      width: 100%;
      height: 25rem;
    }

    .readmore {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }

  .team-container {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

    .team-text {
      margin-left: 0;
      margin-right: 0;

      .team-map {
        width: 100%;
        height: 35rem;
      }
    }



    iframe {
      width: 100%;
    }

    .team-members {
      gap: 2rem;
    }

    .member {
      img {
        height: 12rem;
        width: auto;
      }
    }
  }

  .footer {
    padding-left: 1rem;
    padding-right: 1rem;

    .footer-top {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
    }

    .logo-container {
      img {
        width: 7rem;
        height: auto;
      }
    }

    .footer-main {
      padding: 0;
      flex-direction: column;

      .footer-container {
        margin-top: 2rem;

        .footer-1,
        .footer-2,
        .footer-3,
        .footer-4 {
          display: flex;
          flex-direction: column;
          gap: 3rem;
        }
      }
    }

    .footer-bottom {
      .social {
        gap: 7rem;

        img {
          width: 3.5rem;
          height: auto;
        }
      }
    }
  }

  /**** INDIVIDUAL ********/
  .ind-header-container {
    margin: 5rem 2rem 2rem 2rem;

    .progress-bar-tmp {
      width: 100%;
    }

    .ind-header-top {
      flex-direction: column;

      .left {
        width: auto;

        .subtitle {
          margin-top: 1rem;
        }
      }

      .right {
        img {
          width: 100%;
        }
      }

      .intro {
        width: auto;
      }
    }
  }

  .ind-overview-container {
    padding: 2rem 2rem 2rem 2rem;

    .ind-overview-inner {
      width: auto;

      .list-container {
        .benefits-list-item {
          img {
            margin-right: 2rem;
          }
        }
      }

      .list-title {
        margin: 1rem 0rem 2rem 0rem;
      }
    }
  }

  .ind-overview-footer {
    background: $color-yellow-3;

    .ind-overview-footer-inner {
      width: auto;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      line-height: 3.6rem;
    }
  }

  .ind-testimony-container {
    .ind-testimony-container-inner {
      flex-direction: column;
      margin: 2rem;

      .testimony1-container,
      .testimony2-container {
        width: auto;
        padding: 4rem;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .ind-program-container {
    .feedback {
      flex-direction: column;

      .whatsnext-link-text {
        z-index: 5000;
      }

      .feedback-text-container {
        align-items: flex-start;
      }

      .feedback-text {
        width: auto;
        z-index: 1000;
      }
    }

    .day-selector-container {
      .day-title {
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .title {
      line-height: 4rem;
      background-position: 50% 21%;
      background-size: contain;
      padding-left: 0;
      max-width: 90%;

      .click-container {
        flex-direction: column-reverse;
        align-items: center;
        margin-left: auto;
        margin-top: 3rem;
      }

      .title1 {
        display: inline-block;
        font-size: 5.5rem;
      }

      .title2 {
        display: inline-block;
        color: $color-blue-4;
        font-size: 4rem;
      }
    }

    .row-1,
    .row-2 {
      flex-wrap: wrap;
      gap: 5rem;

      a {
        width: 40%;
      }
    }

    .row-1 {
      margin-top: 0rem;
    }

    .row-2 {
      margin-top: 8rem;
    }

    .ind-testimony-container-bottom {
      flex-direction: column;
      padding: 5rem 2rem 5rem 2rem;

      .testimony-text {
        line-height: 2.6rem;
        width: auto;
      }

    }

    .strip-container {
      .asking-image {
        opacity: 0.9;

        img {
          width: 20rem;
        }
      }
    }
  }

  /*******- GROUP ******/
  .group-header-container {
    padding: 0;
    background-image: none;

    .content {
      width: 100%;
    }

  }

  .group-header-bg {
    position: absolute;
    top: auto;
    left: auto;
  }

  .group-header-content {
    margin: 5rem 2rem 2rem 2rem;


    .title-container {
      .online {
        padding-top: 2rem;
      }
    }

    .title2 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .wyg-container {
    padding-top: 2rem;

    .circle {
      right: 0;
      left: auto;
      z-index: 3;
      max-width: 19rem;
      overflow-x: hidden;
    }

    .top {
      flex-direction: column;
      margin: 2rem;

      img {
        width: 100%;
      }

      .list {
        width: 100%;
      }
    }

    .group-testimony-container {
      margin-top: 2rem;

      .group-testimony-container-inner {
        flex-direction: column;
        margin: 2rem;
        gap: 5rem;
      }

      .group-testimony-container1 {
        width: 100%;
        padding: 4rem;
        background-size: unset;

      }
    }

    .group-wyg-footer-container {
      padding: 2rem;

      .footer-inner {
        width: 100%;
        position: relative;
        left: 0;
        bottom: 0;
        transform: none;
        margin: 0;
      }
    }
  }

  .group-program-container {
    padding: 5rem 2rem 5rem 2rem;

    .gr-text1 {
      width: auto;
    }

    .columns-container {
      flex-direction: column;
    }

    .col-separator {
      display: none;
    }
  }

  .group-international-container {
    padding: 5rem 2rem 5rem 2rem;

    .group-international-image {
      img {
        width: 100%;
      }
    }

    .group-international-text-container {
      position: relative;
      width: auto;
    }
  }

  .next-event-container {
    padding: 5rem 2rem 5rem 2rem;

    .next-event-inner {
      flex-direction: column;

      .next-event-left {
        .next-event-title {
          font-size: 3rem;
        }

        .data-container {
          width: auto;
        }
      }

      .next-event-right {
        opacity:0;
        text-align: center;

        .ns-register-text {
          width: 95%;
          margin-left: auto;
          margin-right: auto;

        }
      }
    }
  }

  .groupevents-container {
    padding: 5rem 2rem 5rem 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .ge-box {
      margin-left: 0;
    }

    .ge-footer {
      .ge-footer-inner {
        width: 100%;
        margin: 2rem;
      }
    }
  }

  /************* THE GIFT ************/
  .thegift-container {
    padding: 5rem 2rem 5rem 2rem;
    width: auto;

    .message-container {
      margin-top: 5rem;
    }

    .gift-video-container {
      position: relative;
      padding-top: 56.25%;

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .message-quote {
      width: 100%;
    }

    .message-text {
      width: 100%;
    }

    .message-logo {
      width: auto;
    }
  }

  /************ OUR TEAM ************/
  .origins-container {
    .map-container {
      margin: 0;

      .map-title {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }

      .map-text {
        line-height: 3rem;
        width: auto;
      }

      .map-inner {
        flex-direction: column;

        .map {
          width: 100%;
          height: 35rem;
          min-width: 10rem;
          min-height: 10rem;
          margin-bottom: 3rem;
        }
      }
    }

    .origins-text3 {
      margin: 0;
      line-height: 3rem;
    }
  }

  .members-container {
    padding: 5rem 2rem 5rem 2rem;

    .members-members {
      flex-direction: row;
      gap: 8rem;

      .member-item {
        flex: 1 1 auto;
        width: 45%;

        .member-image {
          img {
            height: 20rem;
            width: auto;
          }
        }

        .member-text {
          width: auto;
          font-size: 1.5rem;
        }
      }


    }
  }

  /********* CONTACT ****************/
  .contact-container {
    padding: 5rem 2rem 5rem 2rem;

    .follow-container {
      display: none;
    }

    .contact-inner {
      flex-direction: column;

      .contact-right {
        width: 100%;
      }

      .contact-left {
        display: none;
      }
    }

    .contact-row {
      flex-direction: column;
      margin: 0;

      .input-container {
        .form-buttons-container {
          button.button {
            padding: 3rem 6rem 3rem 6rem;
          }
        }
      }
    }

    .input-container {
      margin-top: 5rem;
    }

    .contact-footer-container {
      margin: 5rem 2rem 5rem 2rem;

      .contact-footer-inner {
        width: 100%;

        .footer-title {
          margin-bottom: 5rem;
        }
      }
    }
  }

  /**************** DAYS **********************/
  .day-container {
    padding: 5rem 2rem 5rem 2rem;

    .phrase-container {
      margin-bottom: 3rem;
    }

    .audio-player-container {
      margin-top: 0;
      margin-bottom: 3rem;
    }

    .day-header {
      flex-direction: column;

      .day-title {
        display: inline-block;
        text-align: center;
        margin-bottom: 2rem;
      }
    }

    .day0-intro,
    .day-intro {
      width: 100%;
    }

    .day0-intro {
      .day0-title {
        text-align: center;
      }
    }

    .day0-list {
      width: 100%;
    }

    .day0-list {
      margin: 3rem 0rem 2rem 0rem;
    }

    .day-text-inner {
      width: 100%;
    }

    .reminder-container {
      .reminder-inner {
        width: 100%;
      }

      .reminder-text-inner {
        gap: 1rem;
        padding: 0.3rem 1rem;
      }
    }
  }

  .practice-container {
    padding: 5rem 2rem 5rem 2rem;

    .practice-inner {
      padding-top: 2rem;
    }

    .steps-container {
      flex-direction: column;

      .step {
        .step-text {
          width: 100%;
        }
      }
    }
  }

  /************* FAQ ******************/
  .faq-container {
    padding: 5rem 2rem 5rem 2rem;

    .faq-title {
      margin: 0;
    }

    .faqs-container {
      width: 100%;

      .faq-answer-container {
        .faq-answer {
          .faq-answer-title {
            span {
              line-height: 3rem;
            }
          }

          .faq-answer-text {
            line-height: 3rem;
          }
        }

        .separator {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  /**************** REGISTER *********************/
  .register-container {
    .register-right {
      display: none;
    }

    .register-left {
      padding: 2rem 2rem 2rem 2rem;

      .register-header {
        flex-direction: column;
        gap: 2rem;
        text-align: center;

        .register-title {
          width: 100%;
          line-height: 4rem;
        }

        .register-text {
          width: 100%;
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }


    .register-form-container {
      .register-row {
        flex-direction: column;
        gap: 3rem;

        .input-container {
          margin: 0 auto;
        }
      }
    }

    .form-separator {
      width: 100%;
    }
  }

  .message-box-container {
    padding: 2rem;
    max-width: 90%;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));
  }

  /****** COOKIE ********/
  .cookieconsent-container {
    .cookie-desc {
      line-height: 2rem;
    }

    .cookieconsent-button-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  .cookieconsent-container {
    flex-direction: column;

    .cookieconsent-text-container {
      flex: 1 1 auto;
    }

    .cookieconsent-button-container {
      flex-direction: row;
    }
  }

  /*********- WHATSNEXT ***************/
  .wn-header-container {
    padding-left: 2rem;
    padding-right: 2rem;

    .title {
      font-size: 2.8rem;
    }

    .intro {
      width: 100%;
    }

    .qoute-container {
      width: 100%;

      .arrow-container {
        -webkit-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        left: 50%;
      }

      .quote {
        width: 100%;
        margin-left: 0;
      }
    }

    .wn-h-footer-container {
      width: 100%;
    }

    .options {
      width: 100%;
      text-align: left;
    }
  }

  .participate-container {
    padding-left: 2rem;
    padding-right: 2rem;

    .participate-inner {
      width: auto;

      .participate-title-container {
        width: auto;

        .participate-title {
          text-align: left;
        }
      }
    }

    .community-intro-container {
      flex-direction: column;
      align-items: center;
      margin-top: 3rem;
      width: auto;
      gap: 2rem;

      .community-intro {
        width: auto;
      }
    }

    .community-contact-intro {
      width: auto;
    }


  }

  .youcan-container {
    padding-left: 2rem;
    padding-right: 2rem;


    .title {
      margin-left: 0;
    }

    .list-container {
      width: 100%;

      .item-1,
      .item-2,
      .item-3,
      .item-4,
      .item-5 {
        flex-basis: 100%;
      }

      .item-separator {
        display: none;
      }

      .break {
        display: none;
      }

      .item-title,
      .item-text {
        text-align: center;
      }

      .item-title {
        margin-top: 1rem;
      }

      .youcan-listitem-container {
        align-items: flex-start;

        .youcan-list-item-before {
          margin-top: 1.2rem;
        }
      }
    }

    .footer-container {
      .footer-images {
        gap: 1rem;

        img {
          width: 9rem;
        }
      }
    }

  }

  .wn-footer-container {
    .quote-container {
      width: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .footer-images {
      gap: 2rem;

      img {
        width: 10rem;
      }
    }
  }

  .conclusions-container {
    width: 100%;
    padding-top: 5rem;


    .conclusions-header {
      width: auto;
      padding-left: 2rem;
      padding-right: 2rem;

      .title-container {
        .title {
          font-size: 4rem;
        }
      }

      .audio-player-container {
        .audio-player {
          margin-left: 0;
        }
      }
    }

    .conclusions-questions-container {
      padding-left: 2rem;
      padding-right: 2rem;

      .question-list {
        width: auto;

        .question-list-item {
          .quiestion-item-container {
            .list-decorator {
              img {
                width: 25rem;
              }
            }
          }
        }
      }
    }

    .conclusions-footer-container {
      padding-left: 2rem;
      padding-right: 2rem;
      width: auto;
    }


  }

  .circle-container {
    img {
      width: 15rem;
      height: auto;
    }
  }

  .activate-registration-container {
    min-height: auto;
  }

  /********- CREDIT ***********/
  .credit-container {
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;
  }
  /********- CREDIT ***********/
  .landing-container {
    width: 90%;
    .landing-title {
      font-size: 3rem;
      line-height: normal;
    }

    .landing-title-yellow {
      font-size: 3rem;
      line-height: normal;
      color: $color-yellow-2;
    }


    .landing-inner-right {
      display: none;
    }
    .landing-button-accept {
      font-size: 2rem;
      line-height: normal;
      padding: 1.5rem 4.5rem;
    }
  }
  /********* POST **********/
  .post-container {
    padding: 5rem 2rem 5rem 2rem;
    width: auto;
    .post-content {
      .tagbox-container {
        flex-direction: column;
        .box {
          margin-top: 2rem;
        }
      }
    }
    .box-right {
      margin-left: 0px;
    }

  }

  /********* EVENTS **********/
  .events-container {
    .events-list-container {
      .evenlist-item {
        padding-bottom: 2rem;
        .event-inner-container {
          flex-direction: column;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        .event-inner {
          width: 100%;
        }
      }
    }
  }
  /* resources */
  .nv-iframe-embed iframe {
    width: 100% !important;
    height: 100% !important;
  }
}