.thegift-container {
  font-family: $font-monserrat;
  padding-top: 10rem;
  width: 70rem;
  text-align: center;
  margin: 0 auto;

  .react-player {
    margin: 0 auto;
  }

  .gift-title {
    font-style: normal;
    font-weight: 500;
    font-size: 37.9px;
    line-height: 46px;
    color: $color-blue-1;

    .title2 {
      color: $color-yellow;
    }

  }

  .gift-text1 {
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify;
    color: $color-blue-1;
  }

  .gift-video-container {
    text-align: center;
    margin-top: 5rem;
  }

  .gift-text2 {
    margin-top: 2rem;
    font-style: italic;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 144.9%;
    color: $color-blue-4;
    text-align: justify;

    .spec-qoute {
      font-size: 4rem;
    }
  }

  .gift-image-container {
    margin-top: 2rem;

    img {
      max-width: 100%;
    }
  }
}

.message-container {
  margin-top: 5rem;

  .message-title {
    font-style: normal;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.6rem;
    color: $color-yellow;
  }

  .message-quote-container {
    background-repeat: no-repeat;
    background-position: center 6px;
    background-size: 250px;
    padding-top: 5rem;
    padding-bottom: 6.5rem;

    .message-quote {
      margin-top: 5rem;
      width: 30rem;
      margin-left: auto;
      margin-right: auto;
      font-style: italic;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: $color-green-1;
      text-align: justify;
    }
  }

  .message-text {
    margin-top: 5rem;
    width: 80rem;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 144.15%;
    color: $color-blue-1;
    text-align: left;

    a {
      color: $color-yellow;
    }
  }

  .message-logo {
    width: 80rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    img {
      width: 17.6rem;
      height: auto;
      margin-right: 4rem;
    }
  }
}