.day-container {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .day-header {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 5rem;

    .day-label {
      width: 23rem;
      height: 23rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-style: normal;
      font-weight: 700;
      font-size: 6.7rem;
      line-height: 8.2rem;
      text-align: center;
      color: $color-light-1;
      display: flex;
      justify-content: center;
      align-items: center;

      .day-label-inner {
        width: 18rem;
      }
    }

    .day-title {
      font-style: normal;
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: justify;
      color: $color-yellow;
    }
  }

  .day-intro {
    width: 88rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5rem;

    font-style: normal;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.6rem;
    color: $color-blue-1;

    .day-title {
      font-family: $font-amatic;
      font-style: normal;
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.8rem;
      color: $color-yellow-3;
    }
  }

  .audio-player-container {
    text-align: center;
    margin-top: 5rem;

    .audio-player {
      margin-left: auto;
      margin-right: auto;
      display: inline-block;

      .player-title {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }

  .day-text-inner {
    width: 88rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-1;

    .day-quote {
      font-style: italic;
    }
  }

  .phrase-container {
    margin-top: 5rem;

    .phrase-inner {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 2rem;

      .phrase-text-container {
        width: 45rem;

        .phrase-title {
          font-style: normal;
          font-weight: 500;
          font-size: 2.1rem;
          line-height: 2.6rem;
          color: $color-blue-4;
        }

        .phrase-text {
          font-style: italic;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.6rem;
          color: $color-yellow-2;
        }
      }
    }
  }

  .reminder-container {
    margin-top: 8rem;

    .reminder-title {
      font-style: normal;
      font-weight: 800;
      font-size: 1.4rem;
      line-height: 89.9%;
      color: $color-green-1;
    }

    .reminder-inner {
      width: 66rem;
      margin-left: auto;
      margin-right: auto;
    }

    .reminder-text-container {
      border-radius: 1.3rem;
      margin-top: 0.5rem;
    }

    .reminder-text-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3rem;
      padding: 0.3rem 5.5rem;

      .reminder-text {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: justify;
        color: $color-white;
      }
    }
  }

  .navigation-container {
    margin-top: 10rem;

    .navigation-inner {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      gap: 12rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-4;
    }
  }
}