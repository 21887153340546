.ind-header-container {
  font-family: $font-monserrat;
  margin-bottom: 3rem;

  .ind-header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .left {
      width: 52rem;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: #CDBF49;
    }

    .subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: $color-blue-2;
    }

    .welcome {
      font-family: $font-amatic;
      font-style: normal;
      font-weight: 700;
      font-size: 6.6rem;
      line-height: 8.4rem;
      text-align: center;
      color: $color-green-1;
      margin-top: 2rem;
    }

    .intro {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-2;
      margin-top: 4rem;
      width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .player {
    text-align: center;

    .player-title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: 0.105em;
      color: $color-yellow-3;
    }

    .audio-player {
      margin-left: 0;

      .ap-audio-player {
        display: inline-block;
      }
    }
  }

  .grouptext {
    text-align: center;
    margin-top: 5rem;

    .group1 {
      font-style: italic;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: $color-blue-2;
    }

    .group2 {
      font-style: italic;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: $color-yellow-3;
      margin-top: 2rem;
    }
  }
}

.ind-overview-container {
  background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 56%, rgba(245, 244, 241, 0.19) 95.27%), #F4F5D8;
  font-family: $font-monserrat;
  padding-top: 5rem;

  .ind-overview-inner {
    width: 76rem;
    text-align: left;
    margin: 0 auto;

    .title {
      margin-bottom: 5rem;
    }

    .title1,
    .title2 {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      /* Blue Contrast */
      color: $color-blue-2;
    }

    .title2 {
      color: $color-yellow;
    }

    .intro {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 134.4%;
      color: $color-blue-2;
    }

    .list-title {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: $color-yellow-3;
      margin-left: 7rem;
      margin-bottom: 3rem;
    }

    .list-container {
      .benefits-list-item {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-blue-4;
        margin-bottom: 2rem;

        img {
          margin-right: 3.8rem;
        }

        .list-counter {
          font-style: normal;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $color-yellow;
          margin-right: 0.5rem;
        }
      }
    }

    .remind-me-container {
      margin-top: 5rem;
      padding-bottom: 5rem;

      .remind-me {
        background: rgba(245, 239, 218, 0.75);
        border: 3px solid $color-yellow-2;
        border-radius: 2.6rem;
        width: 20rem;
        margin-left: auto;
        margin-right: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-yellow-3;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        justify-content: center;
      }
    }
  }
}

.ind-overview-footer {
  background: linear-gradient(89.99deg, #F4F5D8 -0.54%, rgba(244, 245, 216, 0) 51.67%, #F4F5D8 109.43%), $color-yellow-3;

  .ind-overview-footer-inner {
    width: 63rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: center;
    color: $color-light-1;
  }
}

.ind-testimony-container {
  background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 56%, rgba(245, 244, 241, 0.19) 95.27%), #F4F5D8;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .question {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    color: $color-yellow-3;
    margin-top: 5rem;
  }

  .ind-testimony-container-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
  }

  .testimony1-container,
  .testimony2-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 7rem;
    width: 46rem;
    font-style: italic;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify;
    color: $color-blue-1;
  }
}

.ind-program-container {
  text-align: center;

  .journey {
    font-family: $font-amatic;
    font-style: normal;
    font-weight: 700;
    font-size: 3.8rem;
    line-height: 4.8rem;
    text-align: center;
    letter-spacing: 0.06em;
    color: $color-yellow;
    margin-top: 3rem;
  }

  .title {
    margin-top: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 8.2rem;
    text-align: center;
    display: inline-block;
    background-repeat: no-repeat;
    background-origin: padding-box;
    padding-left: 2.5rem;
    padding-top: 7rem;
    padding-bottom: 3rem;

    .title1 {
      color: $color-yellow;
      font-size: 6.7rem;
      margin-right: 2.5rem;
    }

    .title2 {
      font-size: 6rem;
      color: $color-blue-2;
    }

    .click-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 2rem;
      margin-left: 25rem;
    }

    .reminder-click-container {
      background: rgba(245, 239, 218, 0.75);
      border: 3px solid $color-yellow-2;
      border-radius: 2.6rem;
      width: 25rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-yellow-3;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-top: -0.25rem;
    }

    .click {
      font-style: normal;
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 2.6rem;
      text-align: center;
      color: $color-blue-4;
    }

    .click-image {
      margin-top: 2rem;
    }
  }

  .row-1,
  .row-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
  }

  .row-1 {
    margin-bottom: 2rem;
  }

  .day-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .day-title {
      width: 10rem;
      height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 89%;
      color: $color-blue-4;
      text-align: center;
    }

    .day-0.day-title,
    .day-1.day-title {
      color: $color-light-1;
    }

    .day-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-1;
      width: 16rem;
    }
  }

  .feedback {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5rem;

    .feedback-text-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .feedback-text {
      width: auto;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-1;
    }
  }

  .strip-container {
    position: relative;

    .strip {
      background: #FAF7ED;
      height: 8.3rem;
      margin-top: 5rem;
      position: relative;
      z-index: 10;
    }

    .asking-image {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;

      img {
        width: 30rem;
      }
    }
  }

  .ind-testimony-container-bottom {
    padding-top: 6rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 7rem;

    .testimony-text {
      width: 30rem;
      margin-top: 3rem;
      font-style: italic;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: justify;
      color: $color-blue-1;
    }
  }
}