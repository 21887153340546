.pp-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-family: $font-monserrat;
  min-height: calc(100vh - 350px);

  .pp-title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: $color-blue-1;
  }

  .pp-content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    color: $color-blue-1;
  }
}