.audio-player {
  margin-left: 10rem;

  .audio-player-left {
    width: 11rem;

    .select-audio {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.2rem;
      color: $color-yellow-3;
      margin-bottom: 1rem;
    }
  }

  .tmp-hide {
    display: none;
  }

  .audio-player-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    img {
      width: 2.9rem;
    }
  }

  .audio-player-current {
    display: flex;
    flex-direction: row;
  }

  .source {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;

    .headphone img {
      width: 1.8rem;
    }

    .source-duration {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.2rem;
      color: $color-blue-1;
      text-decoration: underline;
    }
  }

  .play-button {
    img {
      height: 6rem;
    }
  }

  .ap-audio-player {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .ap-audio-player {
      width: 10rem;
    }

    .forward-backward {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      font-family: monospace;
      font-size: 1.6rem;
      cursor: pointer;
    }

    .forward-backward:hover {
      color: $color-yellow;
    }
  }

  .play-pause {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .play {
    position: relative;
    left: 0.5rem;
  }

  .current-time,
  .duration {
    font-family: $font-monserrat;
    font-size: 1.6rem;
    color: $color-blue-3;
    min-width: 50px;
  }

  .current-time {
    margin-left: 2.5rem;
    margin-right: 0.5rem;
  }

  .duration {
    margin-left: 0.5rem;
  }



  /*********** SLIDER ****************************/
  /********** Range Input Styles **********/
  /*Range Reset*/
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
  }

  /* Removes default focus */
  input[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: $color-blue-1;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: -0.45rem;
    /* Centers thumb on the track */

    /*custom styles*/
    background-color: $color-blue-1;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
  }

  /*input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
  }*/

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: $color-blue-1;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none;
    /*Removes extra border that FF applies*/
    border-radius: 50%;
    /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: $color-blue-1;
    height: 1.5rem;
    width: 1.5rem;
  }

  .progress-bar {

    width: 10rem;
    --bar-bg: $color-blue-1;
    --seek-before-width: 0;
    --seek-before-color: #DFE6D6;
    --knobby: $color-blue-1;
    --selectedKnobby: $color-blue-1;

    appearance: none;
    background: var(--bar-bg);
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: 0.5rem;
    outline: none;
  }

  /* progress bar - safari */
  .progress-bar::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 1rem;
    position: relative;
    width: 100%;
    height: 0.5rem;
    outline: none;
  }

  /* progress bar - firefox */
  .progress-bar::-moz-range-track {
    background: var(--bar-bg);
    border-radius: 1rem;
    position: relative;
    width: 100%;
    height: 0.5rem;
    outline: none;
  }

  .progress-bar::-moz-focus-outer {
    border: 0;
  }

  /* progress bar - chrome and safari */
  .progress-bar::before {
    content: '';
    height: 0.5rem;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  /* progress bar - firefox */
  .progress-bar::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    height: 0.5rem;
  }

  /* knobby chrome safari */
  .progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
  }

  /* knobby while dragging chrome safari */
  .progress-bar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }

  /* knobby firefox */
  .progress-bar::-moz-range-thumb {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }

  /* knobby while dragging firefox */
  .progress-bar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
}