.events-container {
  text-align: center;
  .events-title {
    font-style: normal;
    font-weight: 500;
    font-size: 5rem;
    line-height: 62px;
    text-align: center;
    margin-top: 10rem;
    margin-bottom: 3rem; 
    .events-title-1 {
      color: $color-yellow;
    }
    .events-title-2 {
      color: $color-blue-2;
    }

  }
  .evenlist-item:nth-child(even) {
    background: $color-white; 
  }
  .evenlist-item:nth-child(odd) {
    background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 56%, rgba(245, 244, 241, 0.19) 95.27%), #F4F5D8;
  }

  .events-list-container {
    .evenlist-item {
      padding-bottom: 2rem;
      .event-inner {
        width: 88rem;
        margin: 0 auto;
        .banner-container {
          img {
            max-width: 100%;
          }
        }
        .event-inner-container {
          display:flex;
          justify-content: space-between;
          .event-left {
            flex-basis: 66%;
          }
          .event-right {
            flex-basis: 33%;
            text-align: right;
            margin-top: 2rem;
            .tag-item {
              font-style: normal;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 173.4%;
              text-align: right;
              /* gold */
              color: $color-yellow;
            }
          }
        }

        .event-title {
          font-style: normal;
          font-weight: 600;
          font-size: 2.8rem;
          line-height: 3.5rem;
          text-align: left;
          color: $color-blue-2;
          margin-top: 2rem;
        }
        .event-content {
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $color-blue-2;
          text-align: left;
          margin-top: 5rem;
        }
        .readmore {
          font-style: normal;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 173.4%;
          color: $color-blue-2;
          text-align: right;
        }
      }
    }
  }

}
