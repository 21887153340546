.navbar-container {
  background-color: $color-light-1;
  position: sticky;
  width: 100%;
  top: 0rem;
  left: 0rem;
  z-index: 10;
}

.nav-spacer {
  height: 2.1rem;
  width: 100%;
  background-color: $color-light-1;
  position: absolute;
}

.nav-spacer-hidden {
  display: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 7.7rem;
  align-items: center;
  margin-left: auto;
  margin-right: 0;

  .navbar-brand-name {
    gap: 1rem;
    font-family: $font-monserrat;
    margin-left: 2rem;
    color: $color-blue-1;

    img {
      border-radius: 50%;
      max-height: 4.2rem;
    }
  }

  .nav-links {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-family: $font-monserrat;
    color: $color-blue-1;
    cursor: pointer;

    .menu-top {
      font-family: $font-monserrat;
      font-size: 2.4rem;
      color: $color-blue-1;
      cursor: pointer;
    }
  }

  .nav-links-hover {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    margin-top: 0.3rem;
  }

  .nav-links-hover:after {
    content: '';
    width: 90%;
    height: 0.2rem;
    background-color: $color-yellow;
  }

  .lang-switch-container {
    margin-right: 5rem;
    gap: 2rem;

    span {
      cursor: pointer;
    }
  }

  .page-title {
    font-family: $font-monserrat;
    font-size: 1.9rem;
    font-weight: 800;
    text-transform: capitalize;
    color: $color-blue-2;
    white-space: nowrap;
  }

  .szh-menu-container {
    ul {
      background-color: transparent;
      border: none;
    }

    .szh-menu__item--hover {
      background-color: transparent;
    }

    .szh-menu__item {
      padding: 0.2rem;
    }
  }
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 3.5rem;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 8rem;
}

.nav-item {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 3rem;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: row;
  gap: 6rem;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
}

.dropdown-menu.left70 {
  transform: translate(-70%, 0%);
}


.dropdown-menu li {
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: $color-blue-1;
  padding-top: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 145%;
}