.credit-container {
  font-family: $font-monserrat;
  padding-top: 10rem;
  width: 70rem;
  text-align: center;
  margin: 0 auto;

  .credit-title {
    font-style: normal;
    font-weight: 500;
    font-size: 37.9px;
    line-height: 46px;
    color: $color-blue-1;
  }

  .credit-content {
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify;
    color: $color-blue-1;
  }
}