.group-header-container {
  font-family: $font-monserrat;
  background: linear-gradient(180deg, rgba(245, 239, 218, 0) 0%, rgba(207, 180, 44, 0.29) 59.36%);
  padding-top: 10rem;
  padding-bottom: 5rem;
  overflow: hidden;
  position: relative;

  .group-header-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 70rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.1;
  }

  .group-header-content {
    position: relative;
  }

  .title-container {
    .title1 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: $color-yellow;
    }

    .title2 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: center;
      color: $color-blue-4;
    }

    .online {
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 2.7rem;
      text-align: center;
      color: $color-blue-4;
    }
  }

  .connect {
    margin-top: 5rem;

    .connect1 {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: $color-blue-4;
    }

    .connect2 {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      color: $color-yellow;
    }
  }

  .content {
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    width: 66rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 144.9%;
    text-align: justify;
    color: #787676;
  }

  .zoom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    .text {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 141.9%;
      color: $color-yellow-3;
    }
  }
}

.wyg-container {
  position: relative;
  padding-top: 5rem;

  .circle {
    position: absolute;
    left: 40rem;
    z-index: 3;
  }

  .top {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .left {
      min-width: 12rem;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: $color-yellow;
      margin-bottom: 5rem;
    }

    .list {
      width: 46rem;

      .list-item {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        color: $color-blue-1;
      }
    }
  }

  .group-testimony-container {
    margin-top: 10rem;

    .group-testimony-container-inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;
      gap: 10rem;
    }

    .group-testimony-container1 {
      background-repeat: no-repeat;
      background-position: center;
      padding: 7rem;
      width: 46rem;
      font-style: italic;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 141.9%;
      text-align: justify;
      color: $color-blue-4;
    }
  }

  .doit {
    background: $color-yellow;
    border: 0.5px solid $color-light2;
    box-sizing: border-box;
    border-radius: 291px;
    padding: 2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    line-height: 3.3rem;
    text-align: justify;
    color: $color-light-1;
  }

  .group-wyg-footer-container {
    min-height: 17rem;
    margin-top: -2rem;
    position: relative;

    .footer-inner {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 142%;
      text-align: center;
      color: $color-blue-1;
      width: 87rem;
      position: absolute;
      left: 50%;
      bottom: 1rem;
      transform: translate(-50%, -50%);
      margin: 0 auto;
    }
  }
}

.group-program-container {
  padding-top: 5rem;
  padding-left: 28rem;
  padding-right: 28rem;

  .gr-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 142%;
    text-align: justify;
    color: $color-blue-1;
  }

  .gr-text1 {
    width: 86rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: justify;
    color: $color-blue-1;
  }

  .col-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }

  .columns-container {
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    gap: 4rem;

    .col {
      flex: 1 1 1px;
    }
  }

  .col-title-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: justify;
    color: $color-yellow-3;
  }

  .col-title-image {
    img {
      height: 3rem;
      width: auto;
    }
  }

  .col-separator {
    img {
      margin-top: 6rem;
    }
  }

  .col-text {
    margin-top: 2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 142%;
    text-align: justify;
    color: $color-blue-1;
  }
}

.group-international-container {
  margin-top: 5rem;
  margin-bottom: 14rem;
  position: relative;

  .group-international-image {
    text-align: center;
  }

  .group-international-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 141.9%;
    color: $color-yellow;
  }

  .group-international-text-container {
    width: 45rem;
    position: absolute;
    right: 0px;
    bottom: -8rem;
  }

  .group-international-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 142%;
    color: $color-blue-1;
  }
}

.next-event-container {
  background-color: $color-light-1;
  padding-top: 5rem;
  padding-bottom: 10rem;
  position: relative;

  .dynamic {
    padding-left: 7px;
    padding-right: 7px;
  }

  .next-event-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;

    .next-event-left {
      .next-event-title a {
        
        /*font-style: normal;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 158%;
        text-align: justify;
        color: $color-yellow; */
        background: #C7AF37;
        border: 0.5px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 291px;
        padding: 2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 2.7rem;
        line-height: 3.3rem;
        text-align: justify;
        color: #F5EFDA;
        text-align: center;
      }

      .data-container {
        width: 49rem;
        display: none;
      }

      .ns-register-by {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        margin-top: 3rem;

        .fix {
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-blue-1;
        }

        .dynamic {
          background: $color-white;
          border-radius: 3px;
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-green-1;
        }
      }

      .ns-next-session {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        margin-top: 3rem;

        .fix {
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-blue-1;
        }

        .dynamic {
          background: $color-white;
          border-radius: 3px;
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-green-1;
        }
      }

      .ns-param1 {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        margin-top: 0.5rem;

        .ns-param-name {
          display: flex;
          gap: 1rem;
        }

        .fix {
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-blue-1;
        }

        .dynamic {
          background: $color-white;
          border-radius: 3px;
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 158%;
          text-align: justify;
          color: $color-green-1;
        }
      }

      .ns-footer {
        margin-top: 3rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 157.9%;
        text-align: justify;
        color: $color-blue-1;
        opacity: 0;
      }
    }

    .next-event-right {
      opacity:0;
      .ns-register {
        display: none;
        padding-left: 2rem;
        padding-right: 2rem;
        background: $color-yellow;
        border: 0.539455px solid $color-light2;
        box-sizing: border-box;
        border-radius: 291.845px;
        font-style: normal;
        font-weight: 600;
        font-size: 2.7rem;
        line-height: 3.3rem;
        text-align: justify;
        color: $color-light-1;
      }

      .ns-register-title {
        margin-top: 2rem;
        margin-left: 1rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 142%;
        color: $color-blue-1;
      }

      .ns-register-text {
        margin-left: 2rem;
        font-style: italic;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 142%;
        color: $color-blue-1;
        width: 28rem;
      }
    }
  }
}

.groupevents-container {
  min-height: 61rem;
  position: relative;
  background: linear-gradient(234.31deg, rgba(245, 244, 241, 0.85) 56%, rgba(245, 244, 241, 0.19) 95.27%), #F4F5D8;
  background-blend-mode: overlay, normal;
  mix-blend-mode: normal;

  .groupevents-container-bg {
    min-height: 61rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.8;
  }
}

.ge-box {
  // display: inline-block;
  display: none;
  margin-left: 28rem;
  margin-top: 3rem;

  .ge-title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.6rem;
    color: $color-blue-1;
  }

  .ge-cont-title {
    margin-top: 3rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 142%;
    text-align: justify;
    color: $color-blue-1;
  }

  .ge-item {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 142%;
    text-align: justify;
    color: $color-blue-1;
    margin-top: 0.5rem;
    cursor: pointer;
  }

}

.ge-footer {
  position: absolute;
  left: 0;
  bottom: 5rem;
  right: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 142%;
  color: $color-light-1;

  .ge-footer-inner {
    width: 72.5rem;
    text-align: center;
  }
}