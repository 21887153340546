.benefits-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 10rem;

  .benefits-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    width: 85rem;
  }

  .benefits-text {
    font-family: $font-amatic;
    font-style: normal;
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 6.1rem;
    letter-spacing: 0.04em;
    color: $color-blue-2;
  }

  .benefits-list-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-blue-3;
  }
}

@media (min-width: 991.98px) {
  .benefits-list {
    background: none !important;
  }
}