.testimony-container {
  background-color: #F4F5D8;
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 10rem;
  padding-right: 10rem;
  font-family: $font-monserrat;

  .testimony {
    justify-content: center;
    gap: 14rem;
  }

  .testimony-title {
    text-align: center;
    margin-bottom: 5rem;

    .title1 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      color: $color-yellow;
    }

    .title2 {
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: $color-blue-2;
    }
  }

  .testimony-image {
    img {
      width: 40rem;
    }
  }

  .testimony-text {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-style: italic;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify;
    color: $color-blue-1;
    width: 46rem;
    padding: 5rem;
  }

  .slick-prev {
    left: -6.5rem;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 4rem;
    color: $color-blue-1;
  }

  .slick-arrow {
    z-index: 800;
  }

  .slick-dots {
    bottom: 7rem;
    right: 28rem;
  }

  .testimony-share {
    margin-top: 5rem;
  }

  .testimony-share-inner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .testimony-share-text {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-yellow-3;
  }
}