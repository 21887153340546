//@media (max-width: 575.98px) {
//@media only screen and (min-device-width: 576px) and (max-device-width: 991.98px) {
@media only screen and (max-width: 991.98px) and (orientation: landscape) {

  .program-container-modal {
    height: 30rem;
  }

  .navbar {
    .nav-links {
      padding: 1rem;
    }

    .nav-item {
      margin-bottom: 1rem;
    }

    .nav-menu {
      padding-top: 2rem;
    }
  }

  .header {
    min-height: calc(100vh - 77px);
    height: auto;
    max-height: none;
  }

  .header {
    .header-buttons {
      margin-top: 3rem;
      padding-bottom: 2rem;
      justify-content: center;
      position: relative;
      bottom: auto;
      right: auto
    }
  }

  .benefits-container {
    .benefits-inner {
      flex-direction: row;
      gap: 2rem;
    }

    .benefits-items-separator {
      display: inline;
    }

    .benefits-list {
      background-image: none !important;
      padding-left: 3rem;
    }
  }

  .asking-audio-container {
    .asking-audio-center {
      flex-direction: row;
      align-items: center;
    }

    .audio-player {
      width: 100%;
      transform: translate(50%, 50%);
    }
  }

  .ind-header-container {
    .ind-header-top {
      flex-direction: row;
    }
  }

  .ind-testimony-container {
    .ind-testimony-container-inner {
      flex-direction: row;
    }
  }

  .ind-program-container {

    .row-1,
    .row-2 {
      flex-wrap: nowrap;
      gap: 0rem;

      a {
        width: auto;
      }
    }

    .ind-testimony-container-bottom {
      flex-direction: row;
    }
  }

  .wyg-container {
    .group-testimony-container {
      .group-testimony-container-inner {
        flex-direction: row;
        gap: 1rem;
      }

      a {
        flex: 1 0 auto;
      }

      .group-testimony-container1 {
        width: 100%;
        padding: 3rem;
        background-size: cover;
        flex: 1 1 auto;
      }
    }
  }

  .members-container {
    .members-members {
      .member-text {
        width: 29rem;
      }
    }
  }

  .day-container {
    .day-header {
      flex-direction: row;
    }
  }

  .practice-container {
    .steps-container {
      flex-direction: row;

      img {
        width: 100%
      }

      .step {
        flex: 1 1 30%
      }

      ;
    }
  }

  .register-container {
    .register-form-container {
      .register-row {
        flex-direction: row;
      }
    }
  }

  .audio-player-day-container {
    .audio-player-day-right {
      .audio-player-current {
        width: 100%;

        input[type="range"] {
          width: 100%;
        }
      }
    }
  }
}