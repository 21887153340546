.post-container {
  width: 88rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-family: $font-monserrat;
  min-height: calc(100vh - 350px);
  .banner-container {
    img {
      max-width: 100%;
    }
  }
  .link-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    color: $color-yellow;
    margin-top: 2rem;
  }

  .post-title {
     margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.6rem;
    text-align: center;
    color: $color-blue-2;
    text-align: left;
  }

  .post-content {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify;
    color: $color-blue-1;

    p {
     margin-top: 1rem; 
    }
    .register-button-container {
      margin-top:2rem;
    }
    
    .description-container {
      margin-top:3rem;
      text-align: left;
    }

    .tagbox-container {
      display: flex;
      gap: 10px;
    }

    .box {
      font-size:17px;
      margin-top: 4rem;
    }
    
    .timezone {
      margin-top:2rem; 
      font-size:15px;
      text-align:left;
    }
  }
}