.cookieconsent-container {
  background-color: #F4F5D8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  padding: 2rem;
}

.cookieconsent-text-container {
  flex: 1 0 300px;
  margin: 15px;
}

.cookie-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cookie-text {
  margin-left: 2rem;
  color: #444444;
  font-family: $font-monserrat;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2rem;
  letter-spacing: 0.3px;

  .cookie-desc {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.cookieconsent-button-details {
  color: $color-white;
  background-color: $color-yellow;
  border: none;
  padding: 0 2rem;
  height: 4rem;
}

.cookieconsent-button-accept {
  color: $color-white;
  background-color: $color-yellow;
  border: none;
  padding: 0 2rem;
  margin-left: 2rem;
  height: 4rem;
}