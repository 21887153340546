.footer {
  background-color: $color-blue-1;
  padding: 5rem;
  font-family: $font-monserrat;

  .footer-main {
    display: flex;
    flex-direction: row;
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .footer-container {
    flex: 3;
  }

  .footer-top {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #DFE6D6;
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    justify-content: space-around;
  }

  .logo-container {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #F4F5D8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    img {
      width: 7rem;
      height: max-content;
    }
  }


  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;

    img {
      width: max-content;
      height: max-content;
    }
  }
}