.asking-video-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 10rem;
  padding-bottom: 20rem;
  text-align: center;
  padding: 5rem 10rem 10rem 10rem;
  font-family: $font-monserrat;
  position: relative;

  .asking-video-header-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8rem;
    width: 64rem;
    margin: 0 auto;

    .left {
      flex: 1;
      margin-top: 2rem;
    }

    .right {
      flex: 1;
    }
  }

  .asking-video-title {
    font-style: normal;
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 4.6rem;
    text-align: center;
    text-transform: capitalize;
    color: $color-blue-4;
  }

  .asking-video-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    color: $color-yellow;
  }

  .asking-video-intro {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: $color-blue-4;
    text-align: left;
  }

  .asking-video-extra {
    font-style: italic;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-yellow-3;
  }

  .right-extra {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;

    .asking-video-extra-g img {
      max-width: 1.6rem;
    }

    .asking-video-extra {
      text-align: left;
    }
  }

  .asking-video-video {
    margin-top: 3rem;

    div {
      margin: 0 auto;
    }
  }

  .asking-video-footer-container {
    margin-top: 5rem;
    margin-right: auto;
    margin-left: auto;
    width: 46rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    letter-spacing: 0.215em;
    color: $color-yellow;
  }

  .circle {
    position: absolute;
    bottom: -5px;
    right: 0;
  }
}