.gift-container {
  padding: 10rem 10rem 10rem 10rem;
  text-align: center;

  .gift-title {
    font-size: 4.8rem;
    color: $color-blue-1;
    font-weight: 800;
  }

  .gift-text {
    margin-top: 5rem;
    color: $color-blue-1;
    font-size: 2.6rem;
  }

  .readmore {
    cursor: pointer;
    padding: 2rem;
    margin-left: 2rem;
  }

  .explanation {
    color: $color-blue-1;
    font-size: 2.6rem;

    .gift-video-video,
    .text2 {
      margin: 5rem auto;
    }

    .react-player {
      margin: 0 auto;
    }
  }
}