.day-container {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .day0-top-player {
    margin-top: 5rem;

    .player {
      .player-title {
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-align: justify;
        letter-spacing: 0.105em;
        color: $color-yellow-3;
        text-align: center;
      }

      .audio-player {
        margin-left: 0;

        .ap-audio-player {
          justify-content: center;
        }
      }
    }
  }

  .audio-player-container {
    .below-player {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: $color-yellow;
      margin-top: 2rem;
    }
  }

  .list-counter {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-4;
  }

  .day-header {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 5rem;

    .day-label {
      width: 23rem;
      height: 23rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-style: normal;
      font-weight: 700;
      font-size: 6.7rem;
      line-height: 8.2rem;
      text-align: center;
      color: $color-light-1;
      display: flex;
      justify-content: center;
      align-items: center;

      .day-label-inner {
        width: 18rem;
      }
    }

    .day-title {
      font-style: normal;
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: justify;
      color: $color-yellow;
    }
  }

  .day0-intro {
    width: 63rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;

    .day0-title {
      font-style: normal;
      font-weight: 500;
      font-size: 3.8rem;
      line-height: 4.6rem;
      text-align: justify;
      text-transform: uppercase;
      color: $color-yellow;
    }

    .day0-text {
      margin-top: 2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: $color-green-1;
    }
  }

  .day0-list {
    width: 63rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .list-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-4;

      .list-list-item {
        margin-top: 3rem;
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        img {
          margin-top: 3px;
        }
      }
    }
  }

  .day0-footer-container {
    text-align: center;
    margin-top: 5rem;

    .day0-footer-inner {
      .footer-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        div {
          text-align: left;
          width: 46rem;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $color-yellow;
        }
      }

      .footer-bottom {
        margin-top: 3rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-green-1;
      }
    }
  }

  .navigation-container.day0 {
    margin-top: 5rem;
  }
}

.day-container-day0-bottom {
  padding-top: 0;
}

.practice-container {
  background: rgba(245, 239, 218, 0.6);
  text-align: center;
  padding-bottom: 8rem;

  .practice-inner {
    padding-top: 8rem;
  }

  .practice-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.5rem;
    color: $color-grey-1;
  }

  .practice-text {
    margin-top: 5rem;

    .text1 {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-grey-1;
    }

    .text2 {
      margin-top: 1rem;
      font-family: $font-amatic;
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.5rem;
      color: $color-grey-1;
    }

    .text3 {
      margin-top: 1rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: uppercase;
      color: $color-grey-1;
    }
  }

  .steps-container {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

    .step {
      .step-text {
        width: 20rem;
        margin-top: 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-green-1;
        text-align: left;
      }
    }
  }
}

.page-bottom-container {

  margin-top: 4rem;

  .page-bottom-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .page-bottom-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-yellow-3;
    }
  }
}

.day0-audio-player {
  margin-top: 5rem;

  .audio-player {
    margin-left: auto;

    .audio-player-day-container {
      justify-content: center;
    }
  }

  .below-player {
    text-align: center;
    margin-top: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-yellow;
  }

}