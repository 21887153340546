.signup-container {
  position: fixed;
  left: 50%;
  top: 52%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  /* overflow-y: scroll; */
  height: auto;
  background: $color-light-1;
  border-radius: 1rem;
  z-index: 10000;

  .signup-container-close {
    border-top-right-radius: 1rem;
    float: right;
    cursor: pointer;
    position: relative;
    font-family: $font-monserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.8rem;
    text-align: center;
    letter-spacing: 0.25px;
    color: $color-white;
    background: $color-yellow;
    border: none;
    padding: 5px;
  }
}

.register-container {
  font-family: $font-monserrat;
  display: flex;

  .form-separator {
    width: 64.5rem;
    height: 0rem;
    opacity: 0.2;
    border: 1px dashed #000000;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
  }

  .register-right {
    flex: 1 0 0;
    background-repeat: no-repeat;
    background-position: 60% 50%;
  }

  .register-left {

    padding-top: 2rem;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    flex: 2 0 0;


    .register-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5rem;

      .register-title {
        font-style: normal;
        font-weight: 700;
        font-size: 4rem;
        line-height: 6.2rem;
        color: #42505C;
        width: 39rem;
      }

      .register-text {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.5rem;
        color: $color-green-1;
        opacity: 0.99;
        width: 30rem;
      }
    }
  }

  .register-left.register-group {
    background: #DFE6D6;
    padding-top: 10rem;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 5rem;
    box-shadow: 4rem 0rem 4rem rgba(0, 0, 0, 0.02);

    .register-title {
      font-size: 4rem;
    }
  }


  .register-form-container {
    margin-top: 2rem;

    .register-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 10rem;
      margin-top: 3rem;

      .input-container {
        width: 30rem;
      }

      .input-label {
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 2.8rem;
        letter-spacing: 0.25px;
        color: #9D9B9B;
      }

      input.input,
      input.PhoneInputInput {
        background: $color-white;
        border: 1px solid #D1D1D1;
        height: 4.8rem;
        padding-left: 2rem;
        width: 30rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.8rem;
        letter-spacing: 0.25px;
        color: #000000;
      }

      .button-inner {
        text-align: right;
      }

      button {
        font-family: $font-monserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2.8rem;
        text-align: center;
        letter-spacing: 0.25px;
        color: $color-white;
        background: $color-yellow;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 4.8rem;
        border: none;
      }
    }

    .register-row.register-row-ind {
      margin-top: 1.5rem;
    }
  }

  .register-group .register-form-container {
    margin-top: 5rem;
  }

  .toc-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 2rem;

    input.toc {
      width: 2.4rem;
      height: 2.4rem;
    }

    .label-toc {
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 2.8rem;
      letter-spacing: 0.25px;
      color: #404040;
    }
  }
}