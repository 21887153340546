$font-monserrat: 'Montserrat', sans-serif;
$font-amatic: 'Amatic SC', cursive;

$color-blue-1: #516573;
$color-blue-2: #43515C;
$color-blue-3: #3D4952;
$color-blue-4: #566872;
$color-green-1: #6A7673;
$color-grey-1: #5E5D5D;
$color-yellow: #C7AF37;
//$color-yellow: #FBF9F1;
$color-yellow-2: #CFB42C;
$color-yellow-3: #8E9168;
$color-light-1: #F5EFDA;
$color-light2: #E5E5E5;
$color-white: #ffffff;

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "general";
@import "header";
@import "footer";
@import "navigation";
@import "navigation-mobile";
@import "asking-video";
@import "benefits";
@import "asking-audio";
@import "audio-player";
@import "testimony";
@import "howitworks";
@import "gift";
@import "teampage";
@import "individual-page";
@import "group-page";
@import "silo-page";
@import "day-page";
@import "day0-page";
@import "contact-page";
@import "register-page";
@import "faq-page";
@import "message-box";
@import "cookie";
@import "pp";
@import "credit-page";
@import "conclusions-page";
@import "whatsnext";
@import "subscription";
@import "audio-player-day";
@import "landing";
@import "post";
@import "events";
@import "mobile-portrait";
@import "mobile-landscape";